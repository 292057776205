import React, { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import Layout from '../common/Layout'
import SimOrder from './simOrder'
import TouristsToVietnam from './touristsToVietnam'
import TravelAbroad from './travelAbroad'
import Banner from './banner'
import StepToBuy from './stepToBuy'
import Contact from './contact'
import AboutEsim from './AboutEsim'

const LandingPage = ({isHeaderLandingPage}) => {
  const { t, i18n } = useTranslation('common')
  const currentLanguage = i18n.language
  return (
    <>
      <Layout isHeaderLandingPage={isHeaderLandingPage}>
        <div className="main">
          <div className="landing-page">
            <Banner />
            <TouristsToVietnam />
            <TravelAbroad />
            <AboutEsim />
            <SimOrder />
            <StepToBuy />
            {/*<Contact />*/}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default LandingPage
