import { Button, Form, Input, Modal, Checkbox } from 'antd'
import UploadImage from './UploadImage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'

const patternEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const patternSerial = /^\d{8}$/
function PopupRegisterForTravelSim({
  isOpen,
  setIsOpen,
  loading,
  handleRegisterTravelSim,
}) {
  const { t } = useTranslation('landingPage')
  const [form] = Form.useForm()
  const [passport_image, setPassportImage] = useState(null)
  const [selfie, setSelfieImage] = useState(null)
  const [sim_card_blank, setSimcardblankImage] = useState(null)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      passport_image: passport_image,
    })
    form.validateFields(['passport_image'])
  }, [passport_image])

  useEffect(() => {
    form.setFieldsValue({
      selfie: selfie,
    })
    form.validateFields(['selfie'])
  }, [selfie])

  useEffect(() => {
    form.setFieldsValue({
      sim_card_blank: sim_card_blank,
    })
    form.validateFields(['sim_card_blank'])
  }, [sim_card_blank])

  const onValuesChange = () => {
    setIsValid(checkObjectValidity(form.getFieldsValue()))
  }

  const onFieldsChange = () => {
    setIsValid(checkObjectValidity(form.getFieldsValue()))
  }

  const onFinish = (values) => {
    handleRegisterTravelSim({
      ...values,
    })
  }

  const checkObjectValidity = (obj) => {
    const requiredKeys = [
      'from',
      'to',
      'arrived_date',
      'leave_date',
      'isChecked',
      'serial',
      'passport_image',
      'selfie',
      'sim_card_blank',
    ]

    for (const key of requiredKeys) {
      if (
        !(key in obj) ||
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === '' ||
        obj[key] === false
      ) {
        return false
      }
    }

    if ('serial' in obj && obj['serial']) {
      if (!patternSerial.test(obj['serial'])) return false
    }

    if ('email' in obj && obj['email']) {
      if (!patternEmail.test(obj['email'])) return false
    }

    return true
  }

  useEffect(() => {
    form.resetFields()
  }, [isOpen])

  return (
    <Modal
      width="600px"
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      <div className="popup-sim-owner">
        <div className="sim-owner-title">
          {t('Enter_Sim_Activation_Information')}
        </div>
        <Form
          className="form"
          form={form}
          layout="vertical"
          name="registerForOwner"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            name="from"
            label={t('From')}
            rules={[
              {
                required: true,
                message: t('message_input_your_departure'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="to"
            label={t('To')}
            rules={[
              {
                required: true,
                message: t('message_input_your_destination'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="cccd-div">
            <Form.Item
              name="arrived_date"
              label={t('Departure_Day')}
              rules={[
                {
                  required: true,
                  message: t('message_input_your_departure_day'),
                },
              ]}
            >
              <Input type="date" style={{ minWidth: 210}} />
            </Form.Item>
            <Form.Item
              name="leave_date"
              label={t('Leave_Day')}
              rules={[
                {
                  required: true,
                  message: t('message_input_your_leave_day'),
                },
              ]}
            >
              <Input type="date" style={{ minWidth: 210}} />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            label={t('Email')}
            rules={[
              // {
              //   required: true,
              //   message: t('message_input_your_email'),
              // },
              {
                pattern: patternEmail,
                message: t('message_input_valid_email'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="cccd-div">
            <Form.Item
              name="passport_image"
              label={t('Passport')}
              rules={[
                {
                  required: true,
                  message: t('message_upload_your_passport'),
                },
              ]}
            >
              <UploadImage
                text={t('Passport')}
                handleSetUrl={setPassportImage}
              />
            </Form.Item>
            <Form.Item
              name="selfie"
              label={t('Portrait')}
              rules={[
                {
                  required: true,
                  message: t('message_upload_your_portrait'),
                },
              ]}
            >
              <UploadImage text={t('Portrait')} handleSetUrl={setSelfieImage} />
            </Form.Item>
          </div>

          <div className="cccd-div">
            <Form.Item
              name="sim_card_blank"
              label={t('Sim_Image')}
              rules={[
                {
                  required: true,
                  message: t('message_upload_your_sim_image'),
                },
              ]}
            >
              <UploadImage
                text={t('Sim_Image')}
                handleSetUrl={setSimcardblankImage}
              />
            </Form.Item>

            <div className="phoisim-example">
              <Image
                src="/images/landingPage/phoisim-example-new.png"
                width="270"
                height="125"
              />
            </div>
          </div>

          <Form.Item
            name="serial"
            label={t('serial')}
            rules={[
              {
                required: true,
                message: t('message_input_your_serial'),
              },
              {
                pattern: patternSerial,
                message: t('message_input_valid_serial'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="commit">
            <Form.Item
              name="isChecked"
              valuePropName="checked"
              style={{ marginBottom: 0 }}
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(),
                },
              ]}
            >
              <Checkbox className="text-commit">
                {t('confirm_register')}
              </Checkbox>
            </Form.Item>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
            disabled={!isValid}
            loading={loading}
          >
            {t('Request_Sim_Activation')}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}

export default PopupRegisterForTravelSim
