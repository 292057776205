import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";

const StepToBuy = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [innerWidth, setInnerWidth] = useState(0);
  useEffect(() => {
    setInnerWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className="container">
      <div className="step-cont">
        <div className="step-item">
          {innerWidth < 420 ? (
            <Image
              src={`/images/landingPage/step-1-${currentLanguage}-sm.png`}
              alt={`step-1-${currentLanguage}`}
              width={innerWidth}
              height={innerWidth / 700 * 390}
            />
          ) : innerWidth < 700 ? (
            <Image
              src={`/images/landingPage/step-1-${currentLanguage}-sm.png`}
              alt={`step-1-${currentLanguage}`}
              width={700}
              height={390}
            />
          ) : (
            <Image
              src={`/images/landingPage/step-1-${currentLanguage}.png`}
              alt={`step-1-${currentLanguage}`}
              width={1296}
              height={723}
            />
          )}
        </div>

        <div className="step-item">
          {innerWidth < 420 ? (
            <Image
              src={`/images/landingPage/step-2-${currentLanguage}-sm.png`}
              alt={`step-2-${currentLanguage}`}
              width={innerWidth}
              height={innerWidth / 700 * 390}
            />
          ) : innerWidth < 700 ? (
            <Image
              src={`/images/landingPage/step-2-${currentLanguage}-sm.png`}
              alt={`step-2-${currentLanguage}`}
              width={700}
              height={390}
            />
          ) : (
            <Image
              src={`/images/landingPage/step-2-${currentLanguage}.png`}
              alt={`step-2-${currentLanguage}`}
              width={1296}
              height={723}
            />
          )}
        </div>

        <div className="step-item">
        {innerWidth < 420 ? (
            <Image
              src={`/images/landingPage/step-3-${currentLanguage}-sm.png`}
              alt={`step-3-${currentLanguage}`}
              width={innerWidth}
              height={innerWidth / 700 * 390}
            />
          ) : innerWidth < 700 ? (
            <Image
              src={`/images/landingPage/step-3-${currentLanguage}-sm.png`}
              alt={`step-3-${currentLanguage}`}
              width={700}
              height={390}
            />
          ) : (
            <Image
              src={`/images/landingPage/step-3-${currentLanguage}.png`}
              alt={`step-3-${currentLanguage}`}
              width={1296}
              height={723}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StepToBuy;
