import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import SlickCarousel from '../common/Reuse/slick-carousel'
import callApi from '../../utils/callAPi'
import { message } from 'antd'
import { TRAVEL_ABROAD_TYPE } from '../../constants'
import { currencyConverter } from '../../utils/currencyConverter'
import callApiTemporary from '../../utils/callApiTemporary'

const TravelAbroad = () => {
  const { t, i18n } = useTranslation('travelAbroad')
  const [data, setData] = useState([])
  const [i18nData, setI18nData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data?.length) {
      setI18nData(mappingData(data, i18n.language))
    }
  }, [data, i18n.language])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await callApiTemporary(`/api/v1/landing-products`, {
        params: {
          limit: 50,
          page: 1,
          product_type: TRAVEL_ABROAD_TYPE,
        },
      })
      const data = response?.data?.items
      setData(data)
      setLoading(false)
    } catch (error) {
      message.error('Đã có lỗi trong quá trình xử lý. Vui lòng thử lại sau!')
      setLoading(false)
    }
  }

  const mappingData = (data, language) =>
    data.map((pureData) => ({
      image: pureData?.image,
      id: pureData?.id,
      title: pureData?.title?.[language],
      subtitle: pureData?.subtitle?.[language],
      description: pureData?.description?.[language],
      section: pureData?.section?.[language],
      price: pureData?.price?.[language],
      discount_price: pureData?.discount_price?.[language],
      period_price: pureData?.period_price?.[language],
    }))

  return (
    <div className="container travel-abroad">
      <div className="sim-divider" />
      <div className="title">{t('title')}</div>
      <div className="sub-title">{t('subtitle')}</div>
      <div className="content">{t('description')}</div>
      <SlickCarousel data={i18nData} isTravelAbroad />
    </div>
  )
}

export default TravelAbroad
