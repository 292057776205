import React, { useEffect, useState, useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'next-i18next'
import {
  HOTLINE,
  ID_WECHAT,
  PHONE_NUMBER,
  SOCIAL,
  ID_LINE,
} from '../../../constants'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Link from 'next/link'

const purchase_policy_link =
  'https://drive.google.com/file/d/1g7J1r7DkF6UgyNhZjwnxtQjlBU0oUpqR/view'
const privacy_policy_link =
  'https://drive.google.com/file/d/1eM5ukidY0cQTI_-0MPKlZyKfUAzo2d_-/view'
const general_policy_link =
  'https://drive.google.com/file/d/11cBEeAtYYVkWMSeFHWJWWm7JnmAcg4Vp/view'
const service_center_link =
  'https://drive.google.com/file/d/1pJrBlA6IVdixPj5656rfkguEdA2o_Tpe/view'

const Footer = () => {
  const [mobile, setMobile] = useState(false)
  const router = useRouter()
  useEffect(() => {
    setMobile(isMobile)
  }, [])
  const { t, i18n } = useTranslation('common')
  const language = i18n.language

  const reload = useCallback(() => {
    router.push(language === 'en' ? '/' : `/${language}`)
  }, [language])

  return mobile ? (
    <footer className="footer footer-mobile">
      <div className="footer-top-cont">
        <div className="container">
          <div className="footer-top">
            <Image
              className="footer-logo"
              src="/images/common/logo-v8.png"
              alt="logo"
              width={260}
              height={96}
              onClick={reload}
            />
            <div className="footer-list">
              {/* <div className="footer-list-component">
                <div className="footer-header">
                  {t('footer.featured_products')}
                </div>
                <Link href="/sim-list" className="footer-item">
                  {t('footer.nice_sim_number')}
                </Link>
                <Link
                  href="https://vietnamtouristsimcard.vn/"
                  className="footer-item"
                >
                  {t('footer.travel_sims')}
                </Link>
                <Link href="/coming-soon" className="footer-item">
                  {t('footer.card_service')}
                </Link>
                <Link href="/coming-soon" className="footer-item">
                  {t('footer.accessory')}
                </Link>
              </div> */}
              <div className="footer-list-component">
                <div className="footer-header">
                  <>{t('footer.regulations_and_policies')}</>
                </div>
                <Link
                  target="_blank"
                  href={general_policy_link}
                  className="footer-item"
                >
                  {t('footer.general_policy')}
                </Link>
                <Link
                  target="_blank"
                  href={privacy_policy_link}
                  className="footer-item"
                >
                  {t('footer.privacy_policy')}
                </Link>
                <Link
                  target="_blank"
                  href={purchase_policy_link}
                  className="footer-item"
                >
                  {t('footer.purchase_policy')}
                </Link>
                <Link
                  target="_blank"
                  href={service_center_link}
                  className="footer-item"
                >
                  {t('footer.service_center')}
                </Link>
              </div>
            </div>
            <div>
              <div />
              <div />
              <div className="footer-list-component" style={{ marginTop: 24 }}>
                <div className="footer-header" style={{ marginBottom: 8 }}>
                  {t('footer.contact')}
                </div>
                <div className="footer-item">{t('footer.text_feedback')}</div>
                <div className="phone-comp">
                  <Image
                    src="/images/common/phone.svg"
                    width={28}
                    height={28}
                    alt="phone"
                  />
                  <a href={`tel:${HOTLINE.slice(1)}`}>{HOTLINE}</a>
                  {/* <a href={`tel:+84${PHONE_NUMBER.slice(1)}`}>{PHONE_NUMBER}</a> */}
                </div>
                <div className="mail-comp">
                  <Image
                    src="/images/common/line.svg"
                    width={28}
                    height={28}
                    alt="email"
                  />
                  <a
                    className="contact-item"
                    target="_blank"
                    href={`https://line.me/ti/p/${ID_LINE}`}
                    rel="noreferrer"
                  >
                    {ID_LINE}
                  </a>
                </div>
              </div>
            </div>
            <div className="list-social">
              <Link
                href={SOCIAL.tiktok}
                target="_blank"
                className="social-item"
                rel="noreferrer"
              >
                <Image
                  src="/images/common/tiktok.svg"
                  width={28}
                  height={28}
                  alt="tiktok"
                />
              </Link>
              <Link
                href={SOCIAL.yt}
                target="_blank"
                className="social-item"
                rel="noreferrer"
              >
                <Image
                  src="/images/common/youtube.svg"
                  width={28}
                  height={28}
                  alt="youtube"
                />
              </Link>
              <Link
                href={SOCIAL.fb}
                target="_blank"
                className="social-item"
                rel="noreferrer"
              >
                <Image
                  src="/images/common/facebook.svg"
                  width={28}
                  height={28}
                  alt="facebook"
                />
              </Link>
              <Link
                href={SOCIAL.zalo}
                target="_blank"
                className="social-item"
                rel="noreferrer"
              >
                <Image
                  src="/images/common/zalo.svg"
                  width={28}
                  height={28}
                  alt="zalo"
                />
              </Link>
            </div>
            <div className="copy-right">
              ©Simplus {new Date().getFullYear()},{' '}
              {t('footer.all_rights_reserved')}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-cont">
        <div className="container">
          <div className="footer-bottom">
            <div className="footer-bottom-item">
              <div className="footer-bottom-header">Công ty TNHH Simplus</div>
              <div className="footer-bottom-desciption">
                Số giấy chứng nhận đăng ký kinh doanh: 0110039465. Ngày cấp:
                23/06/2022 do Sở KHĐT TP.Hà Nội cấp
              </div>
              <div className="footer-bottom-desciption">
                Website: vietnamtouristsimcard.vn thuộc quyền sở hữu của công ty
                TNHH MTV Simplus
              </div>
            </div>
            <div className="footer-bottom-item">
              <div className="footer-bottom-header">Địa chỉ</div>
              <div className="footer-bottom-desciption">
                Địa chỉ: 157 Đặng Tiến Đông, Trung Liệt, Đống Đa, Hà Nội
              </div>
            </div>
            {/* <div>
              <Image
                className="thong-bao-bct"
                src="/images/common/thong-bao-bct.png"
                alt="thong-bao"
                width={165}
                height={63}
              />
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  ) : (
    <footer className="footer">
      <div className="footer-top-cont">
        <div className="container">
          <div className="footer-top">
            <div className="footer-list">
              <div className="footer-list-component">
                <div className="footer-header">
                  <>{t('footer.regulations_and_policies')}</>
                </div>
                <Link
                  target="_blank"
                  href={general_policy_link}
                  className="footer-item"
                >
                  {t('footer.general_policy')}
                </Link>
                <Link
                  target="_blank"
                  href={privacy_policy_link}
                  className="footer-item"
                >
                  {t('footer.privacy_policy')}
                </Link>
                <Link
                  target="_blank"
                  href={purchase_policy_link}
                  className="footer-item"
                >
                  {t('footer.purchase_policy')}
                </Link>
                <Link
                  target="_blank"
                  href={service_center_link}
                  className="footer-item"
                >
                  {t('footer.service_center')}
                </Link>
              </div>
              {/* <div className="footer-list-component">
                <div className="footer-header">
                  {t('footer.featured_products')}
                </div>
                <Link href="/sim-list" className="footer-item">
                  {t('footer.nice_sim_number')}
                </Link>
                <Link
                  href="https://vietnamtouristsimcard.vn/"
                  className="footer-item"
                >
                  {t('footer.travel_sims')}
                </Link>
                <Link href="/coming-soon" className="footer-item">
                  {t('footer.card_service')}
                </Link>
                <Link href="/coming-soon" className="footer-item">
                  {t('footer.accessory')}
                </Link>
              </div> */}
              <div className="footer-list-component">
                <div className="footer-header">{t('footer.contact')}</div>
                <div className="footer-item">{t('footer.text_feedback')}</div>
                <div className="phone-comp">
                  <Image
                    src="/images/common/phone.svg"
                    width={28}
                    height={28}
                    alt="phone"
                  />
                  <a href={`tel:+84${HOTLINE.slice(1)}`}>{HOTLINE}</a>
                  {/* <span className="phone-comp-text">{t('or')}</span>
                  <a href={`tel:+84${PHONE_NUMBER.slice(1)}`}>{PHONE_NUMBER}</a> */}
                </div>
                <div className="mail-comp">
                  <Image
                    src="/images/common/line.svg"
                    width={28}
                    height={28}
                    alt="email"
                  />
                  <a
                    className="contact-item"
                    target="_blank"
                    href={`https://line.me/ti/p/${ID_LINE}`}
                    rel="noreferrer"
                  >
                    {ID_LINE}
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-right">
              <Image
                className="footer-logo"
                src="/images/common/logo-v8.png"
                alt="logo"
                width={260}
                height={96}
                onClick={reload}
              />
              <div className="list-social">
                <a
                  href={SOCIAL.tiktok}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/tiktok.svg"
                    width={28}
                    height={28}
                    alt="tiktok"
                  />
                </a>
                <a
                  href={SOCIAL.yt}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/youtube.svg"
                    width={28}
                    height={28}
                    alt="youtube"
                  />
                </a>
                <Link
                  href={SOCIAL.fb}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/facebook.svg"
                    width={28}
                    height={28}
                    alt="facebook"
                  />
                </Link>
                <a
                  href={SOCIAL.zalo}
                  target="_blank"
                  className="social-item"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/common/zalo.svg"
                    width={28}
                    height={28}
                    alt="zalo"
                  />
                </a>
              </div>
              <div className="copy-right">
                ©Simplus {new Date().getFullYear()},{' '}
                {t('footer.all_rights_reserved')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-cont">
        <div className="container">
          <div className="footer-bottom">
            <div className="footer-bottom-item">
              <div className="footer-bottom-header">
                {t('footer.companyName')}
              </div>
              <div className="footer-bottom-desciption">
                {t('footer.companyAddress')}
              </div>
              {/*<div className="footer-bottom-desciption">*/}
              {/*  {t('footer.website')}*/}
              {/*</div>*/}
            </div>
            {/*<div className="footer-bottom-item">*/}
            {/*  <div*/}
            {/*    className="footer-bottom-header"*/}
            {/*  >*/}
            {/*    {t('footer.address')}*/}
            {/*  </div>*/}
            {/*  <div className="footer-bottom-desciption">*/}
            {/*    {t('footer.companyAddress')}*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/* <div>
              <Image
                className="thong-bao-bct"
                src="/images/common/thong-bao-bct.png"
                alt="thong-bao"
                width={107}
                height={41}
              />
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
