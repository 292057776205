import React from 'react'
import Image from 'next/image'
import { ID_LINE, ID_SKYPE, ID_WECHAT, ID_WHATSAPP } from '../../constants'

const Contact = () => {
  return (
    <div className="list-contact">
      <a
        className="contact-item"
        target="_blank"
        href={`https://join.skype.com/invite/${ID_SKYPE}`}
        rel="noreferrer"
      >
        <Image
          src="/images/common/skype.svg"
          alt="skype"
          width={40}
          height={40}
        />
      </a>
      <a
        className="contact-item"
        target="_blank"
        href={`https://api.whatsapp.com/send/?phone=${ID_WHATSAPP}&text&type=phone_number&app_absent=0`}
        rel="noreferrer"
      >
        <Image
          src="/images/common/viber.svg"
          alt="viber"
          width={40}
          height={40}
        />
      </a>
      <a
        className="contact-item"
        target="_blank"
        href={`https://web.wechat.com/${ID_WECHAT}`}
        rel="noreferrer"
      >
        <Image
          src="/images/common/we-chat.svg"
          alt="we-chat"
          width={40}
          height={40}
        />
      </a>
      <a
        className="contact-item"
        target="_blank"
        href={`https://line.me/ti/p/${ID_LINE}`}
        rel="noreferrer"
      >
        <Image
          src="/images/common/line.svg"
          alt="skype"
          width={40}
          height={40}
        />
      </a>
    </div>
  )
}

export default Contact
