import { API_BASE_URL } from '../constants'
import axiosInstance from './auth/axiosInstance'

const callApi = async (url, config = {}) => {
  try {
    const response = await axiosInstance({ url: API_BASE_URL + url, ...config })
    return response.data
  } catch (error) {
    console.error('API Error:', error) // Ghi log lỗi (tuỳ chọn)

    // Trả về một giá trị mặc định hoặc thông báo lỗi tùy chọn
    return null
  }
}

export default callApi
