import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import SlickCarousel from '../common/Reuse/slick-carousel'
import { message } from 'antd'
import { TOURISTS_TO_VIETNAM_TYPE } from '../../constants'
import callApiTemporary from '../../utils/callApiTemporary'

const TouristsToVietnam = () => {
  const { t, i18n } = useTranslation('touristsToVN')

  const [tab, setTab] = useState(1)
  const [tabList, setTabList] = useState([])
  const [data, setData] = useState([])
  const [i18nData, setI18nData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData().then()
  }, [])

  const getUniqueSections = (data, language) =>
    [...new Set(data.map((item) => item.section?.[language]))].filter(Boolean)

  useEffect(() => {
    if (data?.length) {
      setTabList(getUniqueSections(data, i18n.language))
      setI18nData(mappingData(data, i18n.language))
    }
  }, [data, i18n.language])

  useEffect(() => {
    if (data?.length) {
      setI18nData(mappingData(data, i18n.language))
    }
  }, [tab])

  useEffect(() => {
    !!tabList?.length && setTab(localStorage.getItem("TAB_INDEX") && tabList[localStorage.getItem("TAB_INDEX")] || tabList[0])
  }, [tabList])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await callApiTemporary(`/api/v1/landing-products`, {
        params: {
          limit: 50,
          page: 1,
          product_type: TOURISTS_TO_VIETNAM_TYPE,
        },
      })
      const data = response?.data?.items
      setData(data)
      setLoading(false)
    } catch (error) {
      message.error('Đã có lỗi trong quá trình xử lý. Vui lòng thử lại sau!')
      setLoading(false)
    }
  }

  const mappingData = (data, language) =>
    data
      ?.filter((x) => x?.section?.[language] === tab)
      .map((pureData) => ({
        image: pureData?.image,
        id: pureData?.id,
        title: pureData?.title?.[language],
        subtitle: pureData?.subtitle?.[language],
        description: pureData?.description?.[language],
        section: pureData?.section?.[language],
        price: pureData?.price?.[language],
        discount_price: pureData?.discount_price?.[language],
        period_price: pureData?.period_price?.[language],
      }))

  const handleChangeTab = (t, index) => {
    setTab(t)
    localStorage.setItem("TAB_INDEX", index)
  }

  return (
    <div className="container tourists-to-vietnam">
      <div className="sim-divider" />
      <div className="title">{t('title')}</div>
      <div className="content">{t('description')}</div>
      <div className="tab-nav">
        {!!tabList?.length &&
          tabList.map((t, index) => (
            <div
              key={index}
              className={`tab-item ${tab === t && 'active'}`}
              onClick={() => handleChangeTab(t, index)}
            >
              <div className="tab-item-text">{t}</div>
              <div className="tab-divider" />
            </div>
          ))}
      </div>
      <SlickCarousel data={i18nData} />
    </div>
  )
}

export default TouristsToVietnam
