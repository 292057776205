import { Button, Form, Input, Modal, Spin, message } from 'antd'
import { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import callApiTemporary from '../../utils/callApiTemporary'

function PopupCheckActivationStatus({ isOpen, setIsOpen }) {
  const { t } = useTranslation('landingPage')
  const [form] = Form.useForm()

  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const checkStatus = async (serial) => {
    setStatus('')
    try {
      setLoading(true)
      const response = await callApiTemporary(
        `/api/v1/landing-products/search-sim-register-info-by-serial/${serial}`
      )
      switch (response?.data?.status) {
        case 'Approve':
          setStatus('activated')
          break
        case 'Archived':
          setStatus('activated')
          break
        case 'Pending':
          setStatus('not-activated')
          break
        case 'Reject':
          setStatus('activation-failed')
          break
        default:
          setStatus('not-found')
      }
    } catch (error) {
      message.error('Đã có lỗi trong quá trình xử lý. Vui lòng thử lại sau!')
    } finally {
      setLoading(false)
    }
  }

  const onFinish = (values) => {
    checkStatus(values?.serial)
  }

  const generateResult = (status) => {
    switch (status) {
      case 'not-activated':
        return (
          <div className="not-activated-status common-status">
            {t('not_activated')}
          </div>
        )
      case 'activated':
        return (
          <div className="activated-status common-status">
            <div className="activated-status-child">{t('activated')}</div>
          </div>
        )
      case 'activation-failed':
        return (
          <>
            <div className="activation-failed-status common-status">
              {t('activation_failed')}
            </div>
            <div className="fail-description">
              {t('active_fail_decription')}
            </div>
            <div
              className="fail-description"
              style={{ paddingTop: '8px' }}
              dangerouslySetInnerHTML={{
                __html: t('active_fail_contact'),
              }}
            />
          </>
        )
      case 'not-found':
        return (
          <div className="fail-description" style={{ paddingTop: '27px' }}>
            {t('not_found')}
          </div>
        )
      default:
        return
    }
  }

  useEffect(() => {
    form.resetFields()
    setStatus('')
  }, [isOpen])

  return (
    <Modal
      width="600px"
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      <div className="popup-activation-status">
        <div className="title">{t('check_sim_activation_status')}</div>
        <div className="search-block">
          <Form onFinish={onFinish} form={form}>
            <Form.Item
              name="serial"
              rules={[
                {
                  required: true,
                  message: t('message_input_your_serial'),
                },
                {
                  pattern: /^\d{8}$/,
                  message: t('message_input_valid_serial'),
                },
              ]}
            >
              <Input
                size="small"
                prefix={<SearchOutlined />}
                allowClear
                placeholder={t('input_serial')}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="checked-button"
                type="primary"
                htmlType="submit"
              >
                {t('check')}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {loading ? (
          <Spin style={{ marginTop: '20px' }} />
        ) : (
          generateResult(status)
        )}
      </div>
    </Modal>
  )
}

export default PopupCheckActivationStatus
